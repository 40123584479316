.hero-box{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    /* margin-bottom: 30px; */
}

.h-img-box img{
    width: 100%;
    height: 450px;
}
.h-img-box img:hover{
    transform: scale(1.08);
    transition: transform 0.5s ease-out;
}
.h-img-box {
    width: 300px;
    height: 450px
    ;overflow: hidden;
    border: 8px solid silver;
    border-radius: 150px 150px 0px 0px;
    margin-top: 120px;
    margin-bottom: 80px;
}

.hero-left{
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 80px;
}
.hero-title{
   
    z-index: 1;
    margin-top: 20px;
    
}
.hero-title .orange-circle{
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #FF9900;
    left: 60%;
    top: 6%;
    z-index: -1;
}
.hero-title h1{
    font-size: 60px;
    font-weight: 600;
    color: white;
    padding: 20px;
    line-height: 1.2;
    margin-top: 70px;
    z-index: 1;
    position: relative;
}
.hero-title p{
    font-size: 16px;
    color: #707070;
    padding: 30px 20px;
    line-height: 1.4;
}
.search-box{
    width: 90%;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
    background-color: #f5f5f5;
    margin-top: 20px;
    justify-content: space-between;
    color: rgba(23, 130, 252, 0.849);
    margin-bottom: 20px;
    /* padding: 10px; */
    text-align: center;
    margin: 0px auto;
}
.search-box input{
    width: 100%;
    border: none;
    outline: none;
    padding: 4px 15px; 
    background-color: #f5f5f5;
}
.search-box button{
    width: 120px;
    font-size: 20px;
    padding: 4px 5px;
    border: none;
    outline: none;
    background: rgb(0, 132, 255);
    border-radius: 7px;
    color: white;
    cursor: pointer;
}
.search-box button:hover{
    transform: scale(1.04);
    transition: .5s;
}
.stats-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
    text-align: center;
   
}
.stats-item{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* gap: 10px; */
    padding: 10px;
}
.pp-box .countv{
    font-size: 22px;
    margin-right: 5px;
}
.pp-box span{
    font-size: 20px;
    font-weight: 600;
    color: #f39f21;
}
.stats-item p{
    color: #a7a5a5;
    font-size: 14px;
}


@media (max-width:830px) {
    .hero-box{
        flex-direction: column;
        
    }
    .hero-title h1{
        font-size: 50px;
    }
    .hero-title .orange-circle{
        position: absolute;
        left: 50%;
        top: 18px;
        z-index: -1;
    }
    
    
}
@media (max-width:650px) {
    
    
    
}
@media (max-width:530px) {
    
    .hero-title h1{
        font-size: 47px;
    }
    .hero-title .orange-circle{
        position: absolute;
        
        z-index: -1;
    }
    
}
@media (max-width:414px) {
    
    .hero-title .orange-circle{
        position: absolute;
        left: 57%;
        top: 18px;
        z-index: -1;
    }
    
}
@media (max-width:375px) {
    
    .hero-title .orange-circle{
        position: absolute;
        right: 18%;
        top: 18px;
        z-index: -1;
    }
    
}
@media (max-width:344px) {
    
    .hero-title .orange-circle{
        position: absolute;
        right: 10%;
        top: 18px;
        z-index: -1;
    }
    
}