

.copmanies-box{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap:wrap ;
    margin: 10px auto;
    gap: 10px;
    margin: 30px;
    margin-bottom: 80px;
    margin-left: -10px;
}
.copmanies-box img{
    width: 170px;
    margin: 20px;
    
}
