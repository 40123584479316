.App{
    overflow: hidden;
    position: relative;
}
.nav-hero{
    width: 100%;
    /* height: 90vh; */
    background-color: black;
    color: white;
    position: relative;
}
.blur{
    width: 500px;
    height: 400px;
    border-radius: 50%;
    background: rgb(92, 91, 91);
    position: absolute;
    /* z-index: 1; */
    filter: blur(120px);
}