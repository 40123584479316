.residencies-box{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin: 30px auto;
  flex-direction: column;
  width: 100%;
  margin-top: 30px;
 
}
.r-title{
    padding-left:50px ;
    display: flex;
    /* align-items: flex-start; */
    flex-direction: column;
    position: relative;
}
.r-title span{
    font-size: 25px;
    font-weight: 600;
    color: orange;
}
.r-title p{
    font-size: 30px;
    font-weight: 700;
    color: rgb(1, 1, 71);
}
 

.Swiper{
    text-align: center;
    max-width: 90%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 0px auto;
}
.r-card{
   height:310px ;
   border: 1px solid rgb(216, 214, 214);
   padding: 9px;
   border-radius: 8px;
   max-width: max-content;
   margin: auto;
   z-index: 100;
}
.SwiperSlid{
    margin-top: 10px;
    /* height: 280px; */
    width: 250px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.r-card img{
    width: 100%;
    margin: 0px auto;
    height: 200px;
    border-radius: 10px;
    overflow: hidden;
    padding: 2px;
    padding-top: 4px;
}
.r-card img:hover{
    transition:.5s;
    transform: scale(1.03);
}
.card-info{
    text-align: left;
}
.r-card img:hover .card-info{
    transform: scale(0.9);
    transition: .5s;
}
.card-info span{
    
    color: orange;
    font-size: 18px;
}
.card-info span b{
    color:gray;
    font-size: 16px;
}
.card-info h2{
    font-size: 18px;
    color: #1d85e0;
}
.card-info p{
    font-size: 12px;
    color: gray;
}
.r-card:hover{
    /* box-shadow:2px 2px 2px #a1d3fc; */
    background: #e4f2fd;
    cursor: pointer;
    transform: scale(1.05);
    transition: .5s;
}
.swiper-horizontal{
    /* overflow: visible;  */
     text-align: center;
     
    
}

.r-button{
text-align: center;
margin:26px;
}
 
.r-button button{
    padding: 15px;
    margin: 10px;
    outline: none;
    border: none;
    border-radius: 4px;
}
.r-button button:hover{
    background: #c1ddf3;
}
@media (max-width:940px) {
    .r-card{
        height:310px ;
    }
    .card-info span{
    
        color: orange;
        font-size: 16px;
    }
    .card-info span b{
        color:gray;
        font-size: 14px;
    }
    .card-info h2{
        font-size: 16px;
        color: #1d85e0;
    }
}
@media (max-width:850px) {
    .r-title{
        text-align: center;
    }
}
@media (max-width:600px) {
    .r-title{
        padding-left:10px ;
        
    }
    .r-button{
        text-align:center;
        /* margin-left: 60px; */
        }
        .r-card{
            height:305px ;
        }
        .card-info span{
        
            color: orange;
            font-size: 18px;
        }
        .card-info span b{
            color:gray;
            font-size: 16px;
        }
        .card-info h2{
            font-size: 20px;
            color: #1d85e0;
        }
}
  