.nav-box{
    width: 100%;
    /* background-color: rgb(31, 31, 31); */
    height: 73px;
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: white;
    padding: 10px 50px;
    padding-left: 100px;
}
.nav-dark{
    background-color: rgb(49, 49, 49);
    /* z-index: 1; */
}
.logo{
    margin-left: -40px;
}
.logo img{
    height: 50px;
}
.links-box{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.links-box li {
    margin-left: 14px;
    color: rgb(224, 223, 223);
    cursor: pointer;
    transition: 0.5s;
    text-decoration: none;
    list-style: none;

}
/* .links-box li {
    list-style: none;
} */
.links-box li:hover{
    transform: scale(1.02);
    color: white;
    transition: 0.5s;

}
.links-box li button{
    font-size: 18px;
    font-weight: 550;
    color: white;
    background-color: rgb(8, 135, 253);
    padding: 5px 14px;
    border-radius: 10px;
    border: none;
    outline: none;
}
.links-box li button:hover{
    transform: scale(1.03);
    cursor: pointer;
}
.menu-icon{
    display:none ;
    font-size: 20px;
    color: white;
    font-weight: 600;
}

@media (max-width:920px) {
    .links-box{
        z-index: 99;
        position: absolute;
        right: -170px;
        top: 90px;
        flex-direction: column;
        gap: 10px;
        font-size: 20px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        color: rgb(8, 8, 8) ;
        background-color:gray ;
        transition: .5s;
        border-radius: 14px;
        padding: 30px 10px;
        /* padding-left: -10px; */
        margin: auto;
        /* max-width: max-content; */
        cursor: pointer;
    

    }
    .menu-icon{
        display: block;
    }
}

.visi{
    right: 20px;
    
}


@media (max-width:920px){

}
@media (max-width:600px){
    .logo{
        margin-left: -60px;
    }
}