

.value-box{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 50px;
    margin-top: 130px;
    gap: 60px;
}
.img-box img{
    width: 440px;
    height: 590px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.img-box img:hover{
    transform: scale(1.06);
    transition: .5s;
}

.value-left{
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
.img-box{
    border-radius: 220px 220px 0px 0px;
    border: 5px solid silver;
    overflow: hidden;
    text-align: left;
}
.value-right{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    
}

.value-title span{
    font-size: 20px;
    color: orange;
    font-weight: 600;
}
.value-title h1{
    color: rgb(8, 8, 85);
}
.value-title p{
    font-size: 16px;
    color: gray;
}
.dropdown-box{
    margin: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    width: 100%;
}
.value-title{
    margin: 30px;
    /* margin-left: 75px; */
    
}
.dropdown-heading{
    display: flex;
    align-items:center ;
    justify-content: space-between;
    gap: 10px;
    padding: 15px;
    cursor: pointer;
}
.dropdown-heading .icon{
    color:rgba(17, 17, 241, 0.658);
    background: #dbe1fa;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    

}
.heading{
    font-size: 22px;
    color: rgb(12, 12, 73);
}
.dropdown p{
    margin-top: 20px;
    text-align: justify;
    padding: 5px;
    /* display: none; */
}

.dropdown{
  /* border: 1 solid white; */
  /* background-color: rgb(247, 247, 247); */
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  /* box-shadow: 4px 4px 4px black; */
  width: 100%;
}
.Accordion-item{
    margin: 20px 4px;
    overflow: hidden;
    border-radius: 7px;
    box-shadow: 2px 2px 10px rgb(198, 222, 250);
    border: 1px solid rgb(228, 227, 227);
}

@media (max-width:1050px){
    .heading{
        font-size: 17px;
        margin: 0px 5px;
    }
    .img-box img{
        width: 320px;
        height: 480px;
    }
    .img-box{
        border-radius: 187px 187px 0px 0px;
        border: 5px solid silver;
        overflow: hidden;
        text-align: left;
    }
}
@media (max-width:950px) {
    .value-box{
        flex-direction: column;
    }
    .value-title{
        text-align: center;
    }
    .heading{
        font-size: 22px;
        margin: 0px 10px;
    }
}
@media (max-width:530px) {
    .img-box img{
        width: 280px;
        height: 440px;
    }
    .img-box{
        border-radius: 187px 187px 0px 0px;
        border: 5px solid silver;
        overflow: hidden;
        text-align: left;
    }
    .heading{
        font-size: 17px;
        margin: 0px 0px;
    }
    .Accordion-item{
        width: 100%;
    }
    .dropdown{
        width: 100%;
    }
}