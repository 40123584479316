

.footer-box{
    width: 100%;
    background-color: rgb(90, 90, 90);
    margin-top: 160px;
    
}
.footer-box div{
    max-width: 100%;
    margin: 0px auto;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    color: white;
}
.f-left{
    /* padding: 40px; */
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.f-left p{
    font-size: 12px ;
}
.f-right{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.f-right ul{
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 10px;
     gap: 28px;

}
.f-right ul li{
    list-style: none;
}
.f-right ul a{
    text-decoration: none;
    color: white;
    cursor: pointer;
}
.f-right p{
    font-size: 14px;
}
@media (max-width:900px) {
    .footer-box div{
        flex-direction: column;
    }
}
@media (max-width:430px) {
    .f-right ul{
        gap: 14px;
        font-size: 14px;
   
   }
}